<template>
  <div>
    <div class="rates-graph__highcharts">
      <highcharts :options="chartOptions" ref="chart"></highcharts>
    </div>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
export default {
  components: {
    highcharts: Chart
  },
  data: () => ({
    cryptoColors: {
      btc: {
        line: ['#e8b800'],
        bg: [
          [0, 'rgba(232,184,0,0.30)'],
          [1, 'rgba(232,184,0,0.01)']
        ]
      },
      doge: {
        line: ['#E2BF33'],
        bg: [
          [0, 'rgba(226,191,51,0.30)'],
          [1, 'rgba(226,191,51,0.01)']
        ]
      },
      celo: {
        line: ['#E2BF33'],
        bg: [
          [0, 'rgba(226,191,51,0.30)'],
          [1, 'rgba(226,191,51,0.01)']
        ]
      },
      eth: {
        line: ['#627EEA'],
        bg: [
          [0, 'rgba(98,126,234,0.30)'],
          [1, 'rgba(98,126,234,0.01)']
        ]
      },
      etc: {
        line: ['#00A040'],
        bg: [
          [0, 'rgba(0,160,64,0.30)'],
          [1, 'rgba(0,160,64,0.01)']
        ]
      },
      sol: {
        line: ['#8b72dd'],
        bg: [
          [0, 'rgba(139,114,221,0.30)'],
          [1, 'rgba(139,114,221,0.01)']
        ]
      },
      flow: {
        line: ['#00ef8b'],
        bg: [
          [0, 'rgba(0,239,139,0.30)'],
          [1, 'rgba(0,239,139,0.01)']
        ]
      },
      bch: {
        line: ['#8DC351'],
        bg: [
          [0, 'rgba(141,195,81,0.30)'],
          [1, 'rgba(141,195,81,0.01)']
        ]
      },
      ltc: {
        line: ['#BFBBBB'],
        bg: [
          [0, 'rgba(191,187,187,0.30)'],
          [1, 'rgba(191,187,187,0.01)']
        ]
      },
      xrp: {
        line: ['#23292F'],
        bg: [
          [0, 'rgba(35,41,47,0.30)'],
          [1, 'rgba(35,41,47,0.01)']
        ]
      },
      ada: {
        line: ['#0076F3'],
        bg: [
          [0, 'rgba(0,118,243,0.30)'],
          [1, 'rgba(0,118,243,0.01)']
        ]
      },
      bnb: {
        line: ['#F3BA2F'],
        bg: [
          [0, 'rgba(243,186,47,0.30)'],
          [1, 'rgba(243,186,47,0.01)']
        ]
      },
      xlm: {
        line: ['#0F0D57'],
        bg: [
          [0, 'rgba(15,13,87,0.30)'],
          [1, 'rgba(15,13,87,0.01)']
        ]
      },
      egld: {
        line: ['#0d022b'],
        bg: [
          [0, 'rgba(13,2,43,0.30)'],
          [1, 'rgba(13,2,43,0.01)']
        ]
      },
      trx: {
        line: ['#EF0027'],
        bg: [
          [0, 'rgba(239,0,39,0.30)'],
          [1, 'rgba(239,0,39,0.01)']
        ]
      },
      matic: {
        line: ['#AE7EFF'],
        bg: [
          [0, 'rgba(174,126,255,0.30)'],
          [1, 'rgba(174,126,255,0.01)']
        ]
      },
      algo: {
        line: ['#B4285F'],
        bg: [
          [0, 'rgba(180,40,95,0.30)'],
          [1, 'rgba(180,40,95,0.01)']
        ]
      }
    }
  }),
  computed: {
    selRange () {
      return this.$store.state.selRange
    },
    selCrypto () {
      return this.$store.state.selCrypto
    },
    cryptoHistoryApi () {
      return this.$store.state.cryptoHistoryApi
    },
    formatCrypto () {
      const tmpObj = {
        data: [],
        categories: []
      }
      if (this.cryptoHistoryApi[this.selRange][0]) {
        const data = this.cryptoHistoryApi[this.selRange][0][this.selCrypto]
        for (const item in data) {
          const date = new Date(item * 1000)
          const price = Number(Number(data[item]).toFixed(2))
          const dateObj = {
            dateFull: date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }),
            dateDay: date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }),
            dateTime: date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
          }
          if (price > 0) {
            tmpObj.data.push({ y: price, date: dateObj })
            tmpObj.categories.push(this.selRange < 2 ? dateObj.dateTime : dateObj.dateDay)
          }
        }
      }
      return tmpObj
    },
    cryptoHistoryStep () {
      return Math.ceil(this.formatCrypto.data.length / 5)
    },
    yAxisRange () {
      let min = 0
      let max = 0
      if (this.cryptoHistoryApi[this.selRange][0]) {
        const data = this.cryptoHistoryApi[this.selRange][0][this.selCrypto]
        for (const item in data) {
          const price = Number(Number(data[item]).toFixed(2))
          if (price > 0) {
            min = min === 0 ? price : (min > price ? price : min)
            max = max === 0 ? price : (max < price ? price : max)
          }
        }
      }
      return { min, max }
    },
    chartOptions () {
      return {
        chart: { type: 'area' },
        title: { text: '' },
        legend: { enabled: false },
        xAxis: {
          categories: this.formatCrypto.categories,
          labels: {
            step: this.cryptoHistoryStep
          }
        },
        yAxis: [
          {
            title: { text: '' },
            max: this.yAxisRange.max,
            min: this.yAxisRange.min
          },
          {
            opposite: true,
            title: { text: '' }
          }
        ],
        tooltip: {
          shadow: false,
          formatter: function () {
            return `Price: CZK ${this.y}<br/> Date: ${this.point.date.dateFull}`
          }

        },
        series: [
          {
            marker: {
              enabled: false
            },
            name: 'Bitcoin'
          }
        ],
        colors: this.cryptoColors[this.selCrypto].line,
        plotOptions: {
          series: {
            lineWidth: 2,
            animation: {
              duration: 100
            },
            states: {
              hover: {
                lineWidth: 2
              }
            },
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: this.cryptoColors[this.selCrypto].bg
            },
            data: this.formatCrypto.data
          }
        }
      }
    }
  },
  watch: {
    cryptoHistoryApi () {
      this.redrawChart()
    },
    selRange () {
      this.redrawChart()
    },
    selCrypto () {
      this.redrawChart()
    }
  },
  methods: {
    redrawChart () {
      this.$refs.chart.chart.update({ plotOptions: { animation: false } })
      this.$refs.chart.chart.series[0].remove(false)
      this.$refs.chart.chart.addSeries({ data: this.formatCrypto.data })
    },
    cryptoObjFilter (obj) {
      const hist = {
        data: [],
        cat: []
      }
      let min = 0
      let max = 0
      for (const key in obj) {
        const date = new Date(key * 1000)
        const formatDateFull = date.toLocaleString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        })
        const formatDateShort = date.toLocaleString('en-GB', {
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        })
        const price = Number(Number(obj[key]).toFixed(2))
        if (price !== 0) {
          min = min === 0 ? price : price < min ? price : min
          max = max === 0 ? price : price > max ? price : max
          const tmpObj = {
            y: price,
            dateFull: formatDateFull,
            dateShort: formatDateShort
          }
          hist.data.push(tmpObj)
          hist.cat.push(formatDateShort)
        }
      }
      this.yAxisRange.min = min
      this.yAxisRange.max = max
      return hist
    }
  },
  mounted () {
    /* if (!(this.cryptoSelected in this.$store.state.cryptoHistory)) {
      this.fetchData()
    } else {
      if (this.cryptoSelected) {
        this.redrawChart()
      }
    } */
  }
}
</script>
